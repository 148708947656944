import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/material';

export default function FAQAccordian() {
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Box id="faq-accordian">
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography variant='h6' className='color1 fw5'>
                        What services do you offer?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant='h6' className='text-color fw4'>
                        We provide a range of exclusive services tailored to meet the diverse needs of businesses, including web development, UI/UX design, digital marketing, business analysis, software services, and machine learning.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                >
                    <Typography variant='h6' className='color1 fw5'>
                        How can web development benefit my business?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant='h6' className='text-color fw4'>
                        Web development enhances your online presence, improves user experience, and facilitates better interaction with your target audience, ultimately leading to increased visibility and sales.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                >
                    <Typography variant='h6' className='color1 fw5'>
                        What is UI/UX design, and why is it important?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant='h6' className='text-color fw4'>
                        UI/UX design focuses on creating intuitive and visually appealing interfaces that enhance user satisfaction. It is crucial for engaging users, improving usability, and establishing a strong brand identity.
                    </Typography>
                </AccordionDetails>
            </Accordion>

        </Box>
    );
}
