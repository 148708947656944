import { Avatar, Box, Container, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from '@mui/material'
import React from 'react'
import Abt from "../../assets/images/about-us/about-us.png"
import { TitleWithShape } from '../../components/TitleWithShape' 

import Certified from '../../assets/images/about-us/certified.png'; 
import Grow from '../../assets/images/about-us/grow.png';
import CountUp from 'react-countup';
import { ContactUsButton } from '../../buttons/ContactUsButton';

export const AboutusSection = () => {
  return (
    <>

      <Box id="about-us" className="section">
        <Container maxWidth="lg">
          <Grid container alignItems="center" justifyContent='space-between' spacing={4}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Box className="relative no-select" data-aos="zoom-in" data-aos-duration="500">
                <Box className="abt-over" data-aos="zoom-in" data-aos-duration="1200">
                  <Box>
                    <Typography variant='h4'>
                      {/* 25 */}
                      <CountUp end={25} duration={5}/>
                    </Typography>
                    <Typography variant='h6'>
                      Years Experience
                    </Typography>
                  </Box>
                </Box>
                <img src={Abt} className='w100' draggable={false} alt='about us'/>
              </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Box data-aos="fade-up" data-aos-duration="800">
              <Box>
                <TitleWithShape title={"Who We Are"} />
              </Box>
              <Box pt={2}>
                <Typography variant='h2' className='fw6 title-color'>
                  We Are Increasing Business Success With <span className='color1'>IT Solution</span>
                </Typography>
              </Box>

              <Box pt={2}>
                <Typography variant='h6' className='fw4 text-color' gutterBottom>
                  Collaboratively envisioneer user friendly supply chains and cross unit imperative. Authoritativel fabricate competitive resource and holistic synergy. Uniquely generate efficient schemas before future.
                </Typography>
              </Box>
              </Box>



              <Box pt={2} pb={4} data-aos="fade-up" data-aos-duration="800">
                <Grid container alignItems="center" justifyContent='space-between' spacing={4}>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <List>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <img src={Certified} alt='Certified'/>
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={<><Typography variant='h5' className='fw6 title-color' gutterBottom>Quality Products</Typography></>} secondary={<><Typography variant='h6' className='fw4 text-color'>Efficiently transition top-line ideas before market.</Typography></>} />
                      </ListItem>
                    </List>
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <List>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <img src={Grow} alt='Grow'/>
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={<><Typography variant='h5' className='fw6 title-color' gutterBottom>Business Grow</Typography></>} secondary={<><Typography variant='h6' className='fw4 text-color'>Efficiently transition top-line ideas before market.</Typography></>} />
                      </ListItem>
                    </List>
                  </Grid>
                </Grid>
              </Box>

              <Divider />

              <Box pt={4} pb={2} data-aos="fade-up" data-aos-duration="800">
                <Stack direction={"row"} spacing={2}>
                  {/* <Button variant='contained' className='cta-button-2' endIcon={<EastIcon />}>
                    Discover More
                  </Button> */}
                  <ContactUsButton btnClass={"cta-button-2"} />
                </Stack>
              </Box>

            </Grid>
          </Grid>
        </Container>
      </Box>

    </>
  )
}
