import React, { useState } from 'react';
import './Pulvina.css';
import { Box, Button, Container, Grid, Stack } from '@mui/material';

const cardsData = [
  { id: 1, color: '#D9D9D9', title: "Card Number: 01" },
  { id: 2, color: '#D9D9D9', title: "Card Number: 02" },
  { id: 3, color: '#D9D9D9', title: "Card Number: 03" },
  { id: 4, color: '#D9D9D9', title: "Card Number: 04" },
  { id: 5, color: '#D9D9D9', title: "Card Number: 05" }, 
];

function PulvinaCards() {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleCardClick = (index) => {
    setActiveIndex(index);
  };


  const halfLength = Math.ceil(cardsData.length / 2);
  const firstHalf = cardsData.slice(0, halfLength);
  const secondHalf = cardsData.slice(halfLength);

  return (
    <>
      <Container maxWidth="lg">
        <Box className="cards-fullpage">
          <Grid container alignItems="center" justifyContent='space-between' spacing={4}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Box>
                <div className="card-stack">
                  <ul className="card-list">
                    {cardsData.map((card, index) => (
                      <li
                        key={card.id}
                        className={`card ${index === activeIndex ? 'card-top' : ''}`}
                        style={{ backgroundColor: card.color }}
                      >
                        {card.title}
                      </li>
                    ))}
                  </ul>
                </div>
              </Box>
            </Grid>
            <Grid item lg={5} md={6} sm={12} xs={12}>
              <Box>
                <Grid container alignItems="center" justifyContent='space-between' spacing={4}>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Box>
                      <Stack direction='column' spacing={2}>
                        {firstHalf.map((card, index) => (
                          <Button variant='contained' key={card.id} onClick={() => handleCardClick(index)}  className={index === activeIndex ? 'active-button' : ''}>
                            Card {card.id}
                          </Button>
                        ))}
                      </Stack>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Box>
                      <Stack direction='column' spacing={2}>
                        {secondHalf.map((card, index) => (
                          <Button variant='contained' key={card.id} onClick={() => handleCardClick(index + halfLength)} className={index + halfLength === activeIndex ? 'active-button' : ''}>
                            Card {card.id}
                          </Button>
                        ))}
                      </Stack>
                    </Box>
                  </Grid>
                </Grid>

              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}

export default PulvinaCards;
