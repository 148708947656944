import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
// import Logo from "../assets/images/logo.png";
import Logo from "../assets/images/logo.png";
import { Button, Paper, Popover, Stack } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuItem from '@mui/material/MenuItem';
import { Link, useLocation } from 'react-router-dom';
import MobileDrawer from './MobileDrawer';
import "./DropDown.css";


export default function Header() {


    const [anchorEl, setAnchorEl] = React.useState(null);
    const closeTimer = React.useRef(null);

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuMouseEnter = (event) => {
        setAnchorEl(event.currentTarget);
    };



    const [scrollClass, setScrollClass] = React.useState('');

    React.useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setScrollClass('scrolled');
            } else {
                setScrollClass('');
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);




    const location = useLocation();


    const isActive = (paths) => {
        const currentPath = location.pathname + location.hash;
        return paths.some(path => currentPath.includes(path));
    };


    const isHomeActive = () => {
        return location.pathname === '/' && !location.hash;
    };


    const isAboutUsActive = () => {
        return isActive(['/#about-us', '/#our-services', '/#why-choose-us', '/#working-process']);
    };



    const scrollToElement = (id) => {
        const element = document.getElementById(id);
        if (element) {
            const stickyItemsHeight = 50;
            const offset = element.getBoundingClientRect().top - stickyItemsHeight;

            setTimeout(() => {
                window.scrollBy({ top: offset, behavior: 'smooth' });
            }, 50);
        }
    };

    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar id='appbar' position="fixed" className={scrollClass}>
                    <Toolbar>


                        <Link to="/" className={`${isHomeActive(['/']) ? 'active_menu' : ''}`} onClick={() => scrollToElement('hero')} sx={{ flexGrow: 1 }}>
                            <img src={Logo} className='logo' alt='TechSpark Innovations' />
                        </Link>

                        <Box className="desktopviewmenu" sx={{ flexGrow: 1 }}>
                            <Stack direction={"row"} justifyContent={"flex-end"} spacing={2}>
                                <Link to="/" className={`${isHomeActive(['/']) ? 'active_menu' : ''}`} onClick={() => scrollToElement('hero')}>
                                    <Button variant='text' className="header-menu" disableRipple> Home </Button>
                                </Link>




                                <Box className='dropdown'>
                                    <Link href="javascript:void(0)" className="">
                                        <Button variant='text' className="header-menu" disableRipple endIcon={<ArrowDropDownIcon />}> About Us </Button>
                                    </Link>
                                    <Paper className="dropdown-content">
                                        <Paper sx={{ padding: '8px 12px' }}>
                                            <Link to="/#about-us" className={`${isActive(['/#about-us']) ? 'active_menu' : ''}`} onClick={() => scrollToElement('about-us')}>
                                                <MenuItem onClick={handleClose} className='header-dropdown-menu'>Who We Are</MenuItem>
                                            </Link>

                                            <Link to="/#our-services" className={`${isActive(['/#our-services']) ? 'active_menu' : ''}`} onClick={() => scrollToElement('our-services')}>
                                                <MenuItem onClick={handleClose} className='header-dropdown-menu'>Our Services</MenuItem>
                                            </Link>

                                            <Link to="/#why-choose-us" className={`${isActive(['/#why-choose-us']) ? 'active_menu' : ''}`} onClick={() => scrollToElement('why-choose-us')}>
                                                <MenuItem onClick={handleClose} className='header-dropdown-menu'>Why Choose Us</MenuItem>
                                            </Link>

                                            <Link to="/#working-process" className={`${isActive(['/#working-process']) ? 'active_menu' : ''}`} onClick={() => scrollToElement('working-process')}>
                                                <MenuItem onClick={handleClose} className='header-dropdown-menu'>Working Process</MenuItem>
                                            </Link>
                                        </Paper>
                                    </Paper>
                                </Box>




                                {/* <Link to="#" className={`${isAboutUsActive() ? 'active_menu' : ''}`}>
                                    <Button
                                        id="basic-button"
                                        aria-controls={open ? 'basic-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        // onClick={handleButtonClick}
                                        onMouseEnter={handleMenuMouseEnter}
                                        className='header-menu'
                                        endIcon={<ArrowDropDownIcon />}
                                    >
                                        About Us
                                    </Button>
                                </Link> */}


                                {/* <Popover
                                    id="mouse-over-popover"
                                    sx={{
                                        pointerEvents: 'auto', 
                                    }}
                                    open={open}
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                    onClose={handlePopoverClose}
                                    disableRestoreFocus
                                    PaperProps={{
                                        onMouseEnter: () => clearTimeout(closeTimer),
                                        onMouseLeave: handlePopoverClose,
                                    }}
                                >
                                    <Paper sx={{ padding: '8px 12px' }}>
                                        <Link to="/#about-us" className={`${isActive(['/#about-us']) ? 'active_menu' : ''}`} onClick={() => scrollToElement('about-us')}>
                                            <MenuItem onClick={handleClose} className='header-dropdown-menu'>Who We Are</MenuItem>
                                        </Link>

                                        <Link to="/#our-services" className={`${isActive(['/#our-services']) ? 'active_menu' : ''}`} onClick={() => scrollToElement('our-services')}>
                                            <MenuItem onClick={handleClose} className='header-dropdown-menu'>Our Services</MenuItem>
                                        </Link>

                                        <Link to="/#why-choose-us" className={`${isActive(['/#why-choose-us']) ? 'active_menu' : ''}`} onClick={() => scrollToElement('why-choose-us')}>
                                            <MenuItem onClick={handleClose} className='header-dropdown-menu'>Why Choose Us</MenuItem>
                                        </Link>

                                        <Link to="/#working-process" className={`${isActive(['/#working-process']) ? 'active_menu' : ''}`} onClick={() => scrollToElement('working-process')}>
                                            <MenuItem onClick={handleClose} className='header-dropdown-menu'>Working Process</MenuItem>
                                        </Link>
                                    </Paper>
                                </Popover> */}


                                <Link to="/#faq" className={`${isActive(['/#faq']) ? 'active_menu' : ''}`} onClick={() => scrollToElement('faq')}>
                                    <Button variant='text' className="header-menu" disableRipple> FAQ's </Button>
                                </Link>

                                <Link to="/#latest-projects" className={`${isActive(['/#latest-projects']) ? 'active_menu' : ''}`} onClick={() => scrollToElement('latest-projects')}>
                                    <Button variant='text' className="header-menu" disableRipple> Latest Projects </Button>
                                </Link>

                                <Link to="/#testimonials-section" className={`${isActive(['/#testimonials-section']) ? 'active_menu' : ''}`} onClick={() => scrollToElement('testimonials-section')}>
                                    <Button variant='text' className="header-menu" disableRipple> Testimonials </Button>
                                </Link>

                                <Link to="/#contact-us" className={`${isActive(['/#contact-us']) ? 'active_menu' : ''}`} onClick={() => scrollToElement('contact-us')}>
                                    <Button variant='text' className="header-menu" disableRipple> Contact Us </Button>
                                </Link>
                            </Stack>
                        </Box>


                        <Box className='mobileviewmenu' sx={{ flexGrow: 1 }}>
                            <MobileDrawer />
                        </Box>


                    </Toolbar>
                </AppBar>
            </Box>
        </>
    );
}
