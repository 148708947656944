import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const OpenAtTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const hash = window.location.hash;

    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        const offset = 100;
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition - offset;
        window.scrollBy({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    } else {
      const element = document.documentElement || document.body;

      const openAtTopWithDelay = () => {
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 10);
      };

      openAtTopWithDelay();
    }
  }, [pathname]);

  return null;
};

export default OpenAtTop;
  