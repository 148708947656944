import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react';
import { TitleWithShape } from '../../components/TitleWithShape';
import ProcessLine from "../../assets/images/process/process_line.svg"
import Discuss from "../../assets/images/process/discuss.png"
import Create from "../../assets/images/process/create.png"
import Finalize from "../../assets/images/process/final.png"

export const WorkingProcessSection = () => {
  return (
    <>

      <Box id="working-process" className="section">
        <Container maxWidth="lg">


          <Box pb={4}>
            <Grid container alignItems="center" justifyContent='center' spacing={4}>
              <Grid item lg={5} md={6} sm={12} xs={12}>
                <Box data-aos="fade-up" data-aos-duration="800">
                  <Box className="fx_c relative">
                    <Typography variant='h1' className='shadow-title'> PROCESS</Typography>
                    <TitleWithShape title={"WORK PROCESS"} />
                  </Box>
                  <Box pt={2} className="fx_c al_center">
                    <Typography variant='h2' className='fw6 title-color'>
                      Let's See How We <span className='color1'>Work!</span>
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>


          <Box mt={6} mb={6} className="relative">
            <Box data-aos="fade-up" data-aos-duration="1200">
              <img src={ProcessLine} className='process-line' alt='' />
            </Box>
            <Grid container alignItems="center" justifyContent='space-between' spacing={8}>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Box className="work-box" data-aos="fade-up" data-aos-duration="400">
                  <Box className='process-number pulse'>
                    <span>01</span>
                  </Box>
                  <Box className="al_center no-select" pb={2}>
                    <img src={Discuss} className='process-img' alt='Discuss' draggable="false" />
                  </Box>
                  <Box className="al_center">
                    <Typography variant='h5' className='fw6 title-color'>Discuss the Project</Typography>
                  </Box>
                  <Box className="al_center" pt={2}>
                    <Typography variant='h6' className='fw4 text-color'>
                      Start by sharing your project vision. We'll delve into the details, ensuring a thorough understanding of your goals and preferences.
                    </Typography>
                  </Box>
                </Box>
              </Grid>


              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Box className="work-box" data-aos="fade-up" data-aos-duration="1200">
                  <Box className='process-number pulse'>
                    <span>02</span>
                  </Box>
                  <Box className="al_center no-select" pb={2}>
                    <img src={Create} className='process-img' alt='Craft & Create' draggable="false" />
                  </Box>
                  <Box className="al_center">
                    <Typography variant='h5' className='fw6 title-color'>Craft & Create</Typography>
                  </Box>
                  <Box className="al_center" pt={2}>
                    <Typography variant='h6' className='fw4 text-color'>
                      With your vision clear, I'll begin crafting and refining ideas, bringing your project to life with expertise and precision.
                    </Typography>
                  </Box>
                </Box>
              </Grid>


              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Box className="work-box" data-aos="fade-up" data-aos-duration="1800">
                  <Box className='process-number pulse'>
                    <span>03</span>
                  </Box>
                  <Box className="al_center no-select" pb={2}>
                    <img src={Finalize} className='process-img' alt='Finalize & Approve' draggable="false" />
                  </Box>
                  <Box className="al_center">
                    <Typography variant='h5' className='fw6 title-color'>Finalize & Approve</Typography>
                  </Box>
                  <Box className="al_center" pt={2}>
                    <Typography variant='h6' className='fw4 text-color'>
                      We'll collaborate closely to ensure every detail meets your expectations. Once you're satisfied, we'll move forward to final approval, ensuring you're delighted with the end result.
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>


        </Container>
      </Box>

    </>
  )
}
