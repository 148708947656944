import React from 'react';
// import CircularProgress from '@mui/material/CircularProgress';
import Header from '../components/Header';
import LinearScrollProgress from '../components/LinearScrollProgress';
import Backdrop from '@mui/material/Backdrop';
// import { Typewriter } from 'react-simple-typewriter'
import { Button } from '@mui/material';
import AOS from "aos";
import "aos/dist/aos.css";
import LoadingScreen from './LoadingScreen';

const LoaderLayout = ({ children }) => {

    const [loading, setLoading] = React.useState(true);
    React.useEffect(() => {
        const fetchData = async () => {
            setTimeout(() => {
                setLoading(false);
            }, 4050);
            // }, 4050);
        };
        fetchData();
    }, []);

    const skipLoading = () => {
        setLoading(false);
    };


    React.useEffect(() => {
        // AOS.init({ duration: 800 });
        AOS.init();
    });


    return (
        <>
            {loading ? (
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading} className='loader-backdrop'>
                    <LoadingScreen />

                    <Button variant="text" className='skip-loading-button' loading onClick={skipLoading}>
                        Skip Loading
                    </Button>

                </Backdrop>
            ) : (
                <>
                    <Header />
                    <LinearScrollProgress />
                    <main>{children}</main>
                </>
            )}
        </>
    );
};

export default LoaderLayout;
