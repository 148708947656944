import React, { useState, useEffect } from 'react';
// import { Typewriter } from 'react-simple-typewriter';
import { Box, Typography } from '@mui/material';
import AOS from 'aos';
import 'aos/dist/aos.css';

const LoadingScreen = () => {
    const [currentComponentIndex, setCurrentComponentIndex] = useState(0);

    const components = [
        {
            element: (
                <Typography variant='h4' className='no-select white fw3 font2 fx_c' gutterBottom>
                    <Box data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" data-aos-duration="400">
                        Innovation. &nbsp;
                    </Box>
                    <Box data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" data-aos-duration="1200">
                        Excellence. &nbsp;
                    </Box>
                    <Box data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" data-aos-duration="2000">
                        Trust.
                    </Box>
                </Typography>
            ),
            duration: 1500
        },

        {
            element: (
                <Typography variant='h2' className='no-select black fw7 font1 loader-button-left' gutterBottom>
                    {/* This should be displayed for 1 second */}
                </Typography>
            ),
            duration: 500
        },
        {
            element: (
                <>
                    <Typography variant='h2' className='no-select white fw7 font1' gutterBottom>
                        <span data-aos="fade-in-down" data-aos-duration="1500">
                        TechSpark Innovations
                        </span>
                    </Typography>
                    <Typography variant='h4' className='no-select white fw3 font2' gutterBottom>
                        <span data-aos="zoom-in-up" data-aos-duration="1000">
                            Igniting
                        </span>
                        <span data-aos="zoom-in-up" data-aos-duration="2000">
                            <strong> Innovation </strong>,
                        </span>
                        <span data-aos="zoom-in-up" data-aos-duration="2500">
                            Powering
                        </span>
                        <span data-aos="zoom-in-up" data-aos-duration="2800">
                            <strong> Progress </strong>.
                        </span>
                    </Typography>
                </>
            ),
            duration: 1500  // Adjust the duration as per your need
        },
        {
            element: (
                <Typography variant='h2' className='no-select black fw7 font1 loader-button-right' gutterBottom>
                    {/* This should be displayed for 1 second */}
                </Typography>
            ),
            duration: 500
        },
        {
            element: (
                <Typography variant='h2' className='no-select black fw7 font1' gutterBottom>
                    {/* This should be displayed for 1 second */}
                </Typography>
            ),
            duration: 500
        },

    ];

    useEffect(() => {
        AOS.init();

        const currentDuration = components[currentComponentIndex].duration;
        const timeout = setTimeout(() => {
            setCurrentComponentIndex((prevIndex) => (prevIndex + 1) % components.length);
        }, currentDuration);

        return () => clearTimeout(timeout);
    }, [currentComponentIndex, components]);

    return (
        <Box className="al_center">
            {components[currentComponentIndex].element}
        </Box>
    );
};

export default LoadingScreen;
