
import { IconButton } from '@mui/material';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Link } from 'react-router-dom';

const ButtonContainer = styled.div`
  position: fixed;
  bottom: 25px;
  right: 25px;
  align-items: center;
  height: 50px;
  width: 50px;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  opacity: ${({ isScrollButtonVisible }) => (isScrollButtonVisible ? 1 : 0.5)};
  display: ${({ isScrollButtonVisible }) => (isScrollButtonVisible ? 'flex' : 'none')};
  border-radius:50%;
  background-color: #e9eaf8;
  &:hover {
    opacity: 1;
  }
`;

const CircularProgressBar = styled.svg`
  width: 50px;
  height: 50px;
  position: absolute;
  transform: rotate(-90deg); /* Rotate to start at 0 degrees */
`;

const Circle = styled.circle`
  fill: none;
  stroke: #a7464f;
  stroke-width: 5;
  stroke-dasharray: 360; /* Circumference of the circle */
  stroke-dashoffset: ${({ scrollPercentage }) => 360 - (285 * scrollPercentage) / 100};
  transition: stroke-dashoffset 1 s;
`;

export default function BackToTop() {
    const [scroll, setScroll] = useState(0);
    const [showButton, setShowButton] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const checkScrollHeight = () => {
            const currentPosition = window.scrollY;
            if (!showButton && currentPosition > 0) {
                setShowButton(true);
            } else if (showButton && currentPosition <= 0) {
                setShowButton(false);
            }
            setScrollPosition(currentPosition);
        };

        window.addEventListener('scroll', checkScrollHeight);
        return () => {
            window.removeEventListener('scroll', checkScrollHeight);
        };
    }, [showButton]);


    useEffect(() => {
        let progressBarHandler = () => {
            const totalScroll = document.documentElement.scrollTop;
            const windowHeight =
                document.documentElement.scrollHeight -
                document.documentElement.clientHeight;
            const scroll = totalScroll / windowHeight;
            setScroll(scroll);
        };

        window.addEventListener('scroll', progressBarHandler);

        return () => window.removeEventListener('scroll', progressBarHandler);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const scrollPercentage = (scrollPosition / (document.body.scrollHeight - window.innerHeight)) * 100;

    return (
        <>
{/* <Link to="/"> */}
            <ButtonContainer isScrollButtonVisible={showButton} onClick={scrollToTop}>
                <CircularProgressBar viewBox="0 0 100 100">
                    <Circle cx="50" cy="50" r="45" scrollPercentage={scrollPercentage} className='stroke' />
                </CircularProgressBar>
                <IconButton style={{ backgroundColor: '#684df4', padding:'8px' }} size='large'>
                    <KeyboardArrowUpIcon fontSize='medium' className='white' />
                </IconButton>
            </ButtonContainer>
{/* </Link> */}
        </>
    );
}
