import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { v4 as uuidv4 } from 'uuid';

// const nonce = btoa(uuidv4());   Convert UUID to base64 string

const nonce = window.nonceValue;

const cache = createCache({
  key: 'my-prefix-key',
  nonce: nonce,
  prepend: true,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <CacheProvider value={cache}>
      <App />
    </CacheProvider>
  </React.StrictMode>
);
