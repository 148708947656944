import { Box, Button, Container, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
// import HERO from "../../assets/images/hero-2.png"
import HERO from "../../assets/images/hero-2.png"
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import { ContactUsButton } from "../../buttons/ContactUsButton.js";
import { Link } from 'react-router-dom';


export const HeroSection2 = () => {


    const scrollToElement = (id) => {
        const element = document.getElementById(id);
        if (element) {
            const stickyItemsHeight = 50;
            const offset = element.getBoundingClientRect().top - stickyItemsHeight;

            setTimeout(() => {
                window.scrollBy({ top: offset, behavior: 'smooth' });
            }, 50);
        }
    };



    return (
        <Box id="hero" className="hero" pt={"120px"} data-aos="fade-up" data-aos-duration="2000">
            <Container maxWidth="lg">


                <Grid container alignItems="center" justifyContent='center' spacing={2}>
                    <Grid item lg={6} md={7} sm={6} xs={12}>
                        <Box className="al_center" mb={4}>
                            <Box>
                                <Typography variant='h1' className='MainHead  font1 no-select'>
                                    Perfect IT Solutions for Your Business
                                </Typography>
                            </Box>

                            <Box pt={2}>
                                <Typography variant='h4' className="SubHead font2">
                                    Transforming Requirements into Solutions
                                </Typography>
                            </Box>

                            <Box pt={3}>
                                <Stack direction={"row"} justifyContent={"center"} spacing={2}>

                                    <Link to="/#our-services" onClick={() => scrollToElement('our-services')}>
                                        <Button variant='contained' className='cta-button-1' endIcon={<ArrowDownwardIcon />}>
                                            Our Services
                                        </Button>
                                    </Link>

                                    {/* <Button variant='contained' className='cta-button-1' endIcon={<EastIcon />}>
                                        Contact Now
                                    </Button> */}
                                    <ContactUsButton btnClass="cta-button-1" />
                                </Stack>

                            </Box>
                        </Box>
                    </Grid>

                    {/* <Grid item lg={5} md={5} sm={6} xs={12} className='relative'>
                        <Box className="al_left no-select">

                            <img src={HERO} draggable={false} alt="main" className='w100' />

                        </Box>
                    </Grid> */}
                </Grid>

            </Container>
        </Box>
    )
}
