import { Box, Button, Container, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import EastIcon from '@mui/icons-material/East';
import { TitleWithShape } from '../../components/TitleWithShape'
import CodeIcon from '@mui/icons-material/Code';
import DesignServicesOutlinedIcon from '@mui/icons-material/DesignServicesOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined'; 
import { Link } from 'react-router-dom'; 
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';


const cardData = [
  {
    icon: <CodeIcon className='icon-mui' />,
    number: "01",
    heading: 'Web Development',
    desc: 'Intrinsicly redefine competitive e-business before adaptive potentialiti. Professionally build progressive users with.'
  },
  {
    icon: <DesignServicesOutlinedIcon className='icon-mui' />,
    number: "02",
    heading: 'UI/UX Design',
    desc: 'Intrinsicly redefine competitive e-business before adaptive potentialiti. Professionally build progressive users with.'
  },
  {
    icon: <CampaignOutlinedIcon className='icon-mui' />,
    number: "03",
    heading: 'Mobile App Dev',
    desc: 'Intrinsicly redefine competitive e-business before adaptive potentialiti. Professionally build progressive users with.'
  },
  {
    icon: <CodeIcon className='icon-mui' />,
    number: "04",
    heading: 'Digital Marketing',
    desc: 'Intrinsicly redefine competitive e-business before adaptive potentialiti. Professionally build progressive users with.'
  },
  {
    icon: <CodeIcon className='icon-mui' />,
    number: "05",
    heading: 'SEO',
    desc: 'Intrinsicly redefine competitive e-business before adaptive potentialiti. Professionally build progressive users with.'
  },
  {
    icon: <CodeIcon className='icon-mui' />,
    number: "06",
    heading: 'Software Services',
    desc: 'Intrinsicly redefine competitive e-business before adaptive potentialiti. Professionally build progressive users with.'
  },

];

const CardComponent = ({ icon, number, heading, desc }) => (
  <Grid item lg={4} md={4} sm={6} xs={12}>
    <Box className="service-card appear-on-scroll" data-aos="zoom-in-up" data-aos-duration="800">
      <Box className="fx_sb">
        <Box className="shape-icon">
          {icon} {/* Render the icon */}
          <span className='dots'></span>
        </Box>
        <Typography variant='h1' className='service-card_number'>{number}</Typography>
      </Box>

      <Box pt={3}>
        <Typography variant='h4' className='fw6 title-color'>
          {heading} {/* Render the heading */}
        </Typography>
      </Box>
      <Box pt={2}>
        <Typography variant='h6' className='fw4 text-color'>
          {desc} {/* Render the description */}
        </Typography>
      </Box>

      <Box pt={3}>
        <Button variant='contained' className='services-read-more' endIcon={<EastIcon />}>
          Read More
        </Button>
      </Box>
      <Box class="go-corner" href="#">
        <Box class="go-arrow" />
      </Box>
    </Box>
  </Grid>
);



export const OurServicesSection = () => {


  const [showAll, setShowAll] = React.useState(false);

  const handleToggleServices = () => {
    setShowAll(prevState => !prevState);
  };

  const servicesToShow = showAll ? cardData : cardData.slice(0, 3);





  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const stickyItemsHeight = 50;
      const offset = element.getBoundingClientRect().top - stickyItemsHeight;

      setTimeout(() => {
        window.scrollBy({ top: offset, behavior: 'smooth' });
      }, 50);
    }
  };


  return (
    <>

      <Box id="our-services" className="section">
        <Container maxWidth="lg">
          <Grid container alignItems="center" justifyContent='center' spacing={4}>
            <Grid item lg={6} md={7} sm={10} xs={12}>
              <Box data-aos="fade-up" data-aos-duration="800">
                <Box className="fx_c">
                  <TitleWithShape title={"Our Services"} />
                </Box>
                <Box pt={2} className="al_center">
                  <Typography variant='h2' className='fw6 title-color'>
                    We Provide Exclusive <span className='color1'>Service</span> For Your Business
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Box pt={4} pb={4}>
            <Grid container alignItems="center" justifyContent='space-between' spacing={4}>
              {servicesToShow.map((data, index) => (
                <CardComponent key={index} {...data} />
              ))}
            </Grid>
          </Box>

          <Box pt={4} pb={2} data-aos="fade-up" data-aos-duration="800">
            <Stack direction={"row"} spacing={2} justifyContent={"center"}>
              <Link to="/#our-services" onClick={() => scrollToElement('our-services')}>
                <Button variant='contained' className='cta-button-2' endIcon={showAll ? <KeyboardDoubleArrowUpIcon /> : <KeyboardDoubleArrowDownIcon />} onClick={handleToggleServices}>
                  {showAll ? 'Show Less Services' : 'View All Services'}
                </Button>
              </Link>
            </Stack>
          </Box>
        </Container>
      </Box>

    </>
  )
}
