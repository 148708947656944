import { Avatar, Box, Button, Container, Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from '@mui/material'
import React from 'react'
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import logo from '../assets/images/sm-logo.png'
import { Link, useLocation } from 'react-router-dom';


import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';



export const Footer = () => {

  const location = useLocation();

  const isActive = (paths) => {
    const currentPath = location.pathname + location.hash;
    return paths.some(path => currentPath.includes(path));
  };




  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const stickyItemsHeight = 50;
      const offset = element.getBoundingClientRect().top - stickyItemsHeight;

      setTimeout(() => {
        window.scrollBy({ top: offset, behavior: 'smooth' });
      }, 50);
    }
  };







  return (
    <>
      <Box className="before-footer-strip">
        <Container maxWidth="lg">
          <Grid container alignItems="center" justifyContent='space-between' spacing={4}>
            <Grid item lg={3} md={4} sm={6} xs={12} className='relative s'>
              <Box className="footer-logo" data-aos="fade-in" data-aos-duration="1500">
                {/* <Typography variant='h4' className='white fw6'>LOGO Here</Typography> */}
                <img src={logo} alt='logo' className='logo' />
              </Box>
              <Box className="skew" />
            </Grid>

            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Box className="conact-card-list" data-aos="zoom-in-up" data-aos-duration="1500">
                <List>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <CallIcon fontSize='large' />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={<><Typography variant='h6' className='fw4 text-color'>Quick Call Us:</Typography></>}
                      secondary={<><Typography variant='h5' className='fw6 white'>+91 83810 07317</Typography></>} />
                  </ListItem>
                </List>
              </Box>
            </Grid>


            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Box className="conact-card-list" data-aos="zoom-in-up" data-aos-duration="2000">
                <List>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <MailIcon fontSize='large' />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={<><Typography variant='h6' className='fw4 text-color'>Mail Us On:</Typography></>}
                      secondary={<><Typography variant='h5' className='fw6 white'>info@company.com</Typography></>} />
                  </ListItem>
                </List>
              </Box>
            </Grid>


            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Box className="conact-card-list" data-aos="zoom-in-up" data-aos-duration="2500">
                <List>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <LocationOnIcon fontSize='large' />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={<><Typography variant='h6' className='fw4 text-color'>Visit Location:</Typography></>}
                      secondary={<><Typography variant='h5' className='fw6 white'>Nashik, Maharashtra</Typography></>} />
                  </ListItem>
                </List>
              </Box>
            </Grid>


          </Grid>
        </Container>
      </Box>

      <Box id="footer" className="section">
        <Container maxWidth="lg">
          <Box pt={8} pb={2}>
            <Grid container alignItems="flex-start" justifyContent='space-between' spacing={4}>
              <Grid item lg={4} md={4} sm={6} xs={12} data-aos="fade-up" data-aos-duration="1500">
                <Box>
                  <Typography variant='h4' className='footer-grid-title'> About Company </Typography>
                </Box>
                <Box pt={2}>
                  <Typography variant='h6' className='text-color fw4 al_jst'>
                    Professionally redefine transparent ROI through low-risk high-yield imperatives. Progressively create empowered. cost effective users via team driven.
                  </Typography>
                </Box>
                <Box pt={3}>

                  <Stack direction={"row"} justifyContent={"flex-start"} spacing={2}>
                    <a href='https://www.linkedin.com/in/nikwaghchaure7504' target='_blank' rel='noreferrer noopener'>
                      <IconButton className='wbg'>
                        <LinkedInIcon className='li' />
                      </IconButton>
                    </a>

                    <a href='https://instagram.com/nik_waghchaure?igshid=OGQ5ZDc2ODk2ZA==' target='_blank' rel='noreferrer noopener'>
                      <IconButton className='wbg'>
                        <InstagramIcon className='insta' />
                      </IconButton>
                    </a>

                    <a href='https://api.whatsapp.com/send?phone=918381007317' target='_blank' rel='noreferrer noopener'>
                      <IconButton className='wbg'>
                        <WhatsAppIcon className='wa' />
                      </IconButton>
                    </a>

                  </Stack>

                </Box>
              </Grid>

              <Grid item lg={2} md={4} sm={6} xs={6} data-aos="fade-up" data-aos-duration="1500">
                <Box>
                  <Typography variant='h4' className='footer-grid-title'> Quick LInks </Typography>
                </Box>

                <Box pt={2}>
                  <Stack direction={'column'} spacing={1}>
                    <Link to="/#about-us" className={`${isActive(['/#about-us']) ? 'active_menu' : ''}`} onClick={() => scrollToElement('about-us')}>
                      <Button variant='text' className='footer-nav' startIcon={<KeyboardArrowRightIcon />} disableRipple>About Us</Button>
                    </Link>

                    {/* <Button variant='text' className='footer-nav' startIcon={<KeyboardArrowRightIcon />} disableRipple>Team</Button> */}

                    <Link to="/#faq" className={`${isActive(['/#faq']) ? 'active_menu' : ''}`} onClick={() => scrollToElement('faq')}>
                      <Button variant='text' className='footer-nav' startIcon={<KeyboardArrowRightIcon />} disableRipple>FAQ's</Button>
                    </Link>

                    <Link to="/#latest-projects" className={`${isActive(['/#latest-projects']) ? 'active_menu' : ''}`} onClick={() => scrollToElement('latest-projects')}>
                      <Button variant='text' className='footer-nav' startIcon={<KeyboardArrowRightIcon />} disableRipple>Projects</Button>
                    </Link>

                    <Link to="/#contact-us" className={`${isActive(['/#contact-us']) ? 'active_menu' : ''}`} onClick={() => scrollToElement('contact-us')}>
                      <Button variant='text' className='footer-nav' startIcon={<KeyboardArrowRightIcon />} disableRipple>Contact Us</Button>
                    </Link>

                  </Stack>
                </Box>
              </Grid>

              <Grid item lg={3} md={4} sm={6} xs={6} data-aos="fade-up" data-aos-duration="1500">
                <Box>
                  <Typography variant='h4' className='footer-grid-title'> IT Services </Typography>
                </Box>

                <Box pt={2}>
                  <Stack direction={'column'} spacing={1}>

                    <Link to="/#our-services" className={`${isActive(['/#our-services']) ? 'active_menu' : ''}`} onClick={() => scrollToElement('our-services')}>
                      <Button variant='text' className='footer-nav' startIcon={<KeyboardArrowRightIcon />} disableRipple>Website Development</Button>
                    </Link>

                    <Link to="/#our-services" className={`${isActive(['/#our-services']) ? 'active_menu' : ''}`} onClick={() => scrollToElement('our-services')}>
                      <Button variant='text' className='footer-nav' startIcon={<KeyboardArrowRightIcon />} disableRipple>UI/UX Design</Button>
                    </Link>

                    <Link to="/#our-services" className={`${isActive(['/#our-services']) ? 'active_menu' : ''}`} onClick={() => scrollToElement('our-services')}>
                      <Button variant='text' className='footer-nav' startIcon={<KeyboardArrowRightIcon />} disableRipple>Mobile App Dev</Button>
                    </Link>

                    {/* <Link to="/#our-services" className={`${isActive(['/#our-services']) ? 'active_menu' : ''}`} onClick={() => scrollToElement('our-services')}>
                      <Button variant='text' className='footer-nav' startIcon={<KeyboardArrowRightIcon />} disableRipple>UI/UX Design</Button>
                    </Link>

                    <Link to="/#faq" className={`${isActive(['/#faq']) ? 'active_menu' : ''}`} onClick={() => scrollToElement('faq')}>
                      <Button variant='text' className='footer-nav' startIcon={<KeyboardArrowRightIcon />} disableRipple>Cloud Services</Button>
                    </Link> */}

                  </Stack>
                </Box>
              </Grid>

              <Grid item lg={3} md={4} sm={6} xs={12} data-aos="fade-up" data-aos-duration="1500">
                <Box>
                  <Typography variant='h4' className='footer-grid-title'> Recent Posts </Typography>
                </Box>
                <Box pt={2}>
                  <Stack direction={'column'} spacing={1}>


                    <List>
                      <a href='https://www.linkedin.com/posts/nikwaghchaure7504_thrilled-to-share-that-ive-completed-the-activity-7184614420793176064-Yen8?utm_source=share&utm_medium=member_desktop' target='_blank' rel='noopener noreferrer'>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>
                              <img src="https://media.licdn.com/dms/image/D5622AQFCjlVtm3Z8iQ/feedshare-shrink_160/0/1712945560761?e=1718841600&v=beta&t=cfWdqWKkhAPzPjGZ7kKMuOFA1X6HRYUXCb_Nc6Wnrs0" className='w100' alt="The Complete 2024 Web Development Bootcamp" />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={<><Typography variant='h6' className='fw5 white two' gutterBottom> Thrilled to share that I've completed "The Complete 2024 Web Development Bootcamp" on Udemy, guided by the fantastic Angela Yu! </Typography></>}
                            secondary={<><Typography variant='h6' className='fw4 fs13 text-color fx_fs'> <CalendarMonthIcon fontSize='small' className='color1' /> &nbsp; April 13, 2024 </Typography></>} />
                        </ListItem>
                      </a>
                    </List>



                    <Divider />



                    <List>
                      <a href='https://www.linkedin.com/posts/nikwaghchaure7504_progressivewebapp-portfoliowebsite-frontenddesign-activity-7178073459887910912-l7v_?utm_source=share&utm_medium=member_desktop' target='_blank' rel='noopener noreferrer'>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>
                              <img src="https://media.licdn.com/dms/image/D5605AQG9CKghPvOiaA/videocover-low/0/1711386043641?e=1716818400&v=beta&t=1ogEo5SOJmla8BQaE8-XRFgTMJRbHGSEexEyKHbwDN0" className='w100' alt="Introducing My Personal Portfolio Website" />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={<><Typography variant='h6' className='fw5 white two' gutterBottom> Exciting Update: Introducing My Personal Portfolio Website! </Typography></>}
                            secondary={<><Typography variant='h6' className='fw4 fs13 text-color fx_fs'> <CalendarMonthIcon fontSize='small' className='color1' /> &nbsp; April 05, 2024 </Typography></>} />
                        </ListItem>
                      </a>
                    </List>

                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>


      <Box className="footer-copyright-strip">
        <Container maxWidth="lg">
          <Grid container alignItems="center" justifyContent='space-between' spacing={1}>
            <Grid item lg={4} md={4} sm={6} xs={12} className='relative'>
              <Box className="al_left mob_c">
                <Typography variant='h6' className='white fw4'>Copyright &copy; 2024. <Link to="/"> <strong>TechSpark </strong> Technologies. </Link></Typography>
              </Box>
            </Grid>

            <Grid item lg={4} md={4} sm={6} xs={12} className='relative'>
              <Box className="al_right mob_c">
                <Typography variant='h6' className='white fw4'>Designed & Developed by <a href='https://nikhil-waghchaure.netlify.app/' target='_blank' rel='noopener noreferrer'> <strong>Nikhil</strong> </a>.</Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  )
}
