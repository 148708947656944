import * as React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

export default function LinearScrollProgress() {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = document.documentElement.clientHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const maxScroll = documentHeight - windowHeight;
      const scrollPercentage = (scrollPosition / maxScroll) * 100;
      setProgress(scrollPercentage);
    };

    // Set initial progress on component mount
    handleScroll();

    // Add event listener to update progress on scroll
    window.addEventListener('scroll', handleScroll);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Box id="linear-progress" aria-label="Progress Bar">
      <LinearProgress variant="determinate" value={progress} />
    </Box>
  );
}
