import React from 'react'
import { Typography } from '@mui/material'
import Shape from "../assets/images/title-shape.svg";


export const TitleWithShape = ({ title }) => {
    return (
        <>
            <Typography variant='h6' className='color1 fw6 sm-title'> <img src={Shape} draggable={false} alt={title}/> {title} </Typography>
        </>
    )
}
