import { Button } from '@mui/material'
import EastIcon from '@mui/icons-material/East';
import { Link } from 'react-router-dom';



export const ContactUsButton = ({ btnClass }) => {
 
    const scrollToElement = (id) => {
        const element = document.getElementById(id);
        if (element) {
            const stickyItemsHeight = 50;
            const offset = element.getBoundingClientRect().top - stickyItemsHeight;

            setTimeout(() => {
                window.scrollBy({ top: offset, behavior: 'smooth' });
            }, 50);
        }
    };



    return (
        <>

            <Link to="/#contact-us" onClick={() => scrollToElement('contact-us')}>
                <Button variant='contained' className={btnClass} endIcon={<EastIcon />}>
                    Contact Now
                </Button>
            </Link>
        </>
    )
}
