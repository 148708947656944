import { Box, Typography } from '@mui/material';
import './Root.css';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material';


import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Routes
} from "react-router-dom";
import { Home } from './pages/Home';
import Header from './components/Header';
import BackToTop from './components/BackToTop';
import OpenAtTop from './components/OpenAtTop';
import LinearScrollProgress from './components/LinearScrollProgress';
import LoaderLayout from './layouts/LoaderLayout';
import PulvinaCards from './pages/PulvinaCards';


function App() {

  const theme = createTheme({
    typography: {
      fontFamily: 'Roboto, sans-serif',
    },
  });



  return (
    <ThemeProvider theme={theme}>
      <Router>
        {/* <Header/> */}
        {/* <LinearScrollProgress/> */}
        <BackToTop />
        <OpenAtTop />
        <Routes>
          <Route exact path="/" element={<LoaderLayout><Home /></LoaderLayout>} />
          {/* <Route exact path="/about" element={<Home />} /> */}
          <Route exact path="/pulvina" element={<PulvinaCards />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
