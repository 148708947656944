import { Box, Button, Container, Chip, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import EastIcon from '@mui/icons-material/East';
import { TitleWithShape } from '../../components/TitleWithShape'

import Project1 from '../../assets/images/projects/project1.png';
import Project2 from '../../assets/images/projects/project2.png';
import Project3 from '../../assets/images/projects/project3.png';
import Project4 from '../../assets/images/projects/project4.png';
import Project5 from '../../assets/images/projects/project5.png';
import Project6 from '../../assets/images/projects/project6.png';


import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';




const cardData = [
  {
    chip1: "HTML",
    chip2: "Bootstrap",
    Img: Project1,
    content: "This Site is Built usign HTML and Bootstrap.",
    href: "https://nik-waghchaure.github.io/Explore_Nik.github.io/"
  },
  {
    chip1: "HTML",
    chip2: "Bootstrap",
    Img: Project2,
    content: "This Site is Built usign HTML and Bootstrap.",
    href: "https://nik-waghchaure.github.io/Kulswamini_Trading/"
  },
  {
    chip1: "React.Js",
    chip2: "Material UI",
    Img: Project3,
    content: "This Site is Built usign React.Js and Material UI.",
    href: "https://gaming-metaverse.netlify.app/"
  },
  {
    chip1: "React.Js",
    chip2: "Material UI",
    Img: Project4,
    content: "This Site is Built usign React.Js and Material UI.",
    href: "https://one-page-course-site.netlify.app/"

  },
  {
    chip1: "React.Js",
    chip2: "Material UI",
    Img: Project5,
    content: "This Site is Built usign React.Js and Material UI.",
    href: "https://real-estate-deals.netlify.app/"

  },
  {
    chip1: "React.Js",
    chip2: "Material UI",
    Img: Project6,
    content: "This Site is Built usign React.Js and Material UI.",
    href: "https://sandbox-marketing-solutions.netlify.app/"

  },
];

const CardComponent = ({ chip1, chip2, Img, content, href }) => (
  <Grid item lg={4} md={4} sm={6} xs={12}>
    <Box className="project-card" data-aos="zoom-in-up" data-aos-duration="800">
      <Box className="chips">
        <Stack direction={"row"} spacing={2}>
          <Chip className='chip1' label={chip1} />
          <Chip className='chip2' label={chip2} />
        </Stack>
      </Box>
      <figure class="effect-chico">
        <img src={Img} alt="project" />
        <figcaption>
          <Typography variant='h6' className='fw5 white desc'>{content}</Typography>

          <a href={href} target='_blank' className='btn-link' rel="noreferrer">
            <Button variant='contained' className='visit-now desc' endIcon={<EastIcon fontSize='small' />}>
              Visit Now
            </Button>
          </a>
        </figcaption>
      </figure>
    </Box>
  </Grid>
);


export const LatestProjectsSection = () => {




  const [showAll, setShowAll] = React.useState(false);

  const handleToggleServices = () => {
    setShowAll(prevState => !prevState);
  };

  const servicesToShow = showAll ? cardData : cardData.slice(0, 3);




  return (
    <>

      <Box id="latest-projects" className="section">
        <Container maxWidth="lg">
          <Box>
            <Grid container alignItems="center" justifyContent='space-between' spacing={4}>
              <Grid item lg={5} md={6} sm={12} xs={12}>
                <Box data-aos="fade-up" data-aos-duration="800">
                  <Box>
                    <TitleWithShape title={"Latest Project"} />
                  </Box>
                  <Box pt={2}>
                    <Typography variant='h2' className='fw6 title-color'>
                      Explore Our Completed  <span className='color1'>IT Solutions</span>
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item lg={7} md={6} sm={12} xs={12}>
                <Box className="fx_fe" data-aos="fade-up" data-aos-duration="800">
                  <Stack direction={"row"} spacing={2}>
                    {/* <Button variant='contained' className='cta-button-2' endIcon={<EastIcon />}>
                      View All Projects
                    </Button> */}

                    <Button variant='contained' className='cta-button-2' endIcon={showAll ? <KeyboardDoubleArrowUpIcon /> : <KeyboardDoubleArrowDownIcon />} onClick={handleToggleServices}>
                      {showAll ? 'Show Less Projects' : 'View All Projects'}
                    </Button>


                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </Box>


          <Box mt={4}>
            <Grid container alignItems="center" justifyContent='space-between' spacing={3}>
              {/* {cardData.map((data, index) => (
                <CardComponent key={index} {...data} />
              ))} */}
              {servicesToShow.map((data, index) => (
                <CardComponent key={index} {...data} />
              ))}
            </Grid>
          </Box>


        </Container>
      </Box>

    </>
  )
}
