import { Box, Button, Container, Divider, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import EastIcon from '@mui/icons-material/East';
import Img from "../../assets/images/faq-side.png"
import { TitleWithShape } from '../../components/TitleWithShape'
import FAQAccordian from './FAQAccordian';

export const FAQSection = () => {





  return (
    <>

      <Box id="faq" className="section">
        <Container maxWidth="lg">
          <Grid container alignItems="center" justifyContent='space-between' spacing={4}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Box className="relative no-select" data-aos="zoom-in" data-aos-duration="500">
                <Box className="abt-over" data-aos="zoom-in" data-aos-duration="1000">
                  <Box>
                    <Typography variant='h4'>
                      100+
                    </Typography>
                    <Typography variant='h6'>
                      Queries Answered
                    </Typography>
                  </Box>
                </Box>
                <img src={Img} className='w100' draggable={false} alt='About Us' width={'90%'} />
              </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Box data-aos="fade-up" data-aos-duration="800">
                <Box>
                  <TitleWithShape title={"FAQ's"} />
                </Box>
                <Box pt={2}>
                  <Typography variant='h2' className='fw6 title-color'>
                    Answers to Common <span className='color1'>Queries</span>
                  </Typography>
                </Box>
              </Box>

              <Box pt={2} pb={2} data-aos="fade-up" data-aos-duration="1000">
                <FAQAccordian />
              </Box>

              <Divider />

              <Box pt={3} pb={2} data-aos="fade-up" data-aos-duration="800">
                <Stack direction={"row"} spacing={2}>
                  <Button variant='contained' className='cta-button-2' endIcon={<EastIcon />}>
                    View All
                  </Button>
                </Stack>
              </Box>

            </Grid>
          </Grid>
        </Container>
      </Box>

    </>
  )
}
