import * as React from 'react';
// import "./Drawer.css";
import Box from '@mui/material/Box'; 
import IconButton from '@mui/material/IconButton';
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { Drawer, Divider, Button, AccordionDetails, MenuItem, Stack } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import { Link, useLocation } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CallIcon from '@mui/icons-material/Call';


export default function MobileDrawer() {

  const location = useLocation();


  const isActive = (paths) => {
    const currentPath = location.pathname + location.hash;
    return paths.some(path => currentPath.includes(path));
  };


  const isHomeActive = () => {
    return location.pathname === '/' && !location.hash;
  };


  const isAboutUsActive = () => {
    return isActive(['/#about-us', '/#our-services', '/#why-choose-us', '/#working-process']);
  };



  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const stickyItemsHeight = 50;
      const offset = element.getBoundingClientRect().top - stickyItemsHeight;

      setTimeout(() => {
        window.scrollBy({ top: offset, behavior: 'smooth' });
      }, 50);
    }
  };
  const [state, setState] = React.useState({});
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'right' && (event.key === 'right')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };



  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };



  return (
    <>
      {['right'].map((anchor) => (
        <>

          <Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} spacing={1}>

            <IconButton style={{ background: "#684df417" }}>
              <CallIcon fontSize="medium" className='color1' />
            </IconButton>


            <IconButton onClick={toggleDrawer(anchor, true)} style={{ background: "#684df417" }}>
              <MenuIcon fontSize="medium" className='color1' />
            </IconButton>
          </Stack>


          <Drawer
            className='drawer'
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            <Box className='mobilemenuview' sx={{ width: 220 }} role="presentation">


              <Box mb={1} mt={1} mr={2} className='al_right'>
                <IconButton onClick={toggleDrawer(anchor, false)}>
                  <CloseIcon fontSize='medium' className='color1' />
                </IconButton>
              </Box>

              <Divider />

              <Box>

                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} onClick={toggleDrawer(anchor, false)}>
                  <AccordionSummary>
                    <Link to="/" className={`w100 ${isHomeActive(['/']) ? 'active_menu' : ''}`} onClick={() => scrollToElement('hero')}>
                      <Button variant='text' className="header-menu" disableRipple fullWidth> Home </Button>
                    </Link>
                  </AccordionSummary>
                </Accordion>


                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Link to="#" className={`w100 ${isAboutUsActive() ? 'active_menu' : ''}`}>
                      <Button variant='text' className="header-menu" disableRipple fullWidth> About Us </Button>
                    </Link>
                  </AccordionSummary>
                  <AccordionDetails onClick={toggleDrawer(anchor, false)}>
                    <Link to="/#about-us" className={`${isActive(['/#about-us']) ? 'active_menu' : ''}`} onClick={() => scrollToElement('about-us')}>
                      <MenuItem className='header-menu'>Who We Are</MenuItem>
                    </Link>

                    <Link to="/#our-services" className={`${isActive(['/#our-services']) ? 'active_menu' : ''}`} onClick={() => scrollToElement('our-services')}>
                      <MenuItem className='header-menu'>Our Services</MenuItem>
                    </Link>

                    <Link to="/#why-choose-us" className={`${isActive(['/#why-choose-us']) ? 'active_menu' : ''}`} onClick={() => scrollToElement('why-choose-us')}>
                      <MenuItem className='header-menu'>Why Choose Us</MenuItem>
                    </Link>

                    <Link to="/#working-process" className={`${isActive(['/#working-process']) ? 'active_menu' : ''}`} onClick={() => scrollToElement('working-process')}>
                      <MenuItem className='header-menu'>Working Process</MenuItem>
                    </Link>
                  </AccordionDetails>
                </Accordion>


                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} onClick={toggleDrawer(anchor, false)}>
                  <AccordionSummary>
                    <Link to="/#faq" className={`w100 ${isActive(['/#faq']) ? 'active_menu' : ''}`} onClick={() => scrollToElement('faq')}>
                      <Button variant='text' className="header-menu" disableRipple fullWidth> FAQ's </Button>
                    </Link>
                  </AccordionSummary>
                </Accordion>

                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} onClick={toggleDrawer(anchor, false)}>
                  <AccordionSummary>
                    <Link to="/#latest-projects" className={`w100 ${isActive(['/#latest-projects']) ? 'active_menu' : ''}`} onClick={() => scrollToElement('latest-projects')}>
                      <Button variant='text' className="header-menu" disableRipple fullWidth> Latest Projects </Button>
                    </Link>
                  </AccordionSummary>
                </Accordion>


                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} onClick={toggleDrawer(anchor, false)}>
                  <AccordionSummary>
                    <Link to="/#testimonials-section" className={`w100 ${isActive(['/#testimonials-section']) ? 'active_menu' : ''}`} onClick={() => scrollToElement('testimonials-section')}>
                      <Button variant='text' className="header-menu" disableRipple fullWidth> Testimonials </Button>
                    </Link>
                  </AccordionSummary>
                </Accordion>

                <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')} onClick={toggleDrawer(anchor, false)}>
                  <AccordionSummary>
                    <Link to="/#contact-us" className={`w100 ${isActive(['/#contact-us']) ? 'active_menu' : ''}`} onClick={() => scrollToElement('contact-us')}>
                      <Button variant='text' className="header-menu" disableRipple fullWidth> Contact Us </Button>
                    </Link>
                  </AccordionSummary>
                </Accordion>

              </Box>

              <Box>
              </Box>
            </Box>
          </Drawer>
        </>
      ))}
    </>
  );
}
