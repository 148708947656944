import React from 'react'
// import { HeroSection } from './Sections/HeroSection'
import { AboutusSection } from './Sections/AboutusSection'
import { WhyChooseUsSection } from './Sections/WhyChooseUsSection'
import { OurServicesSection } from './Sections/OurServicesSection'
import { LatestProjectsSection } from './Sections/LatestProjectsSection'
import { FAQSection } from './Sections/FAQSection'
import { TestimonialSection } from './Sections/TestimonialSection'
import { Footer } from '../components/Footer'
import { Box } from '@mui/material'
import { ContactUsSection } from './Sections/ContactUsSection'
import { WorkingProcessSection } from './Sections/WorkingProcessSection'
import { HeroSection2 } from './Sections/HeroSection2' 
// import HeroSectionBootstrap from './Sections/HeroSectionBootstrap'

export const Home = () => {

 
  React.useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
        const element = document.querySelector(hash);
        if (element) {
            const offset = 0;
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition = elementPosition - offset;
            window.scrollBy({
                top: offsetPosition,
                behavior: "smooth",
            });
        }
    }
}, []);

  return (
    <>


      {/* <HeroSection /> */}
      <HeroSection2/>
      {/* <HeroSectionBootstrap/> */}
      <AboutusSection />
      <OurServicesSection />
      <WhyChooseUsSection />
      <WorkingProcessSection/>
     {/* <Box id="faq"> */}
     <FAQSection />
     {/* </Box> */}
      <LatestProjectsSection />
      <TestimonialSection />
      <Box mt={"240px"} className="tab-hide"/>
      <ContactUsSection/>
      <Footer /> 

    </>
  )
}
