import { Box, Container, Grid, Typography, Rating, Divider } from '@mui/material'
import React from 'react';
import { TitleWithShape } from '../../components/TitleWithShape';
import BG from "../../assets/images/testimonials/bill-gates.jpg";
import LP from "../../assets/images/testimonials/larry-page.jpg";
import SP from "../../assets/images/testimonials/sundar-pichai.jpg";
import MZ from "../../assets/images/testimonials/mark-zukerberg.jpg";

import FormatQuoteOutlinedIcon from '@mui/icons-material/FormatQuoteOutlined';

import CarouselMulti from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";





const testimonialsData = [
  {
    img: BG,
    rating: 4,
    description: 'Objectively visualize error-free tech for B2B alignment. Monotonectally harness an expanded array of models via effective collaboration. Globally synergize resource sucking value via cutting-edge.',
    name: 'Bill Gates',
    designation: 'Former CEO of Microsoft'
  },
  {
    img: LP,
    rating: 3.5,
    description: 'Objectively visualize error-free tech for B2B alignment. Monotonectally harness an expanded array of models via effective collaboration. Globally synergize resource sucking value via cutting-edge.',
    name: 'Larry Page',
    designation: 'Former CEO of Google'
  },
  {
    img: SP,
    rating: 4.5,
    description: 'Objectively visualize error-free tech for B2B alignment. Monotonectally harness an expanded array of models via effective collaboration. Globally synergize resource sucking value via cutting-edge.',
    name: 'Sundar Pichai',
    designation: 'CEO of Google'
  },
  {
    img: MZ,
    rating: 3,
    description: 'Objectively visualize error-free tech for B2B alignment. Monotonectally harness an expanded array of models via effective collaboration. Globally synergize resource sucking value via cutting-edge.',
    name: 'Mark Zukerberg',
    designation: 'CEO of Facebook'
  },
];

const TestimonialComponent = ({ img, rating, description, name, designation }) => (
  <>
    <Box className="testimonial-card no-select">
      <Box className="testimonial-img">
        <img src={img} draggable={false} alt={name} />
        <Box className='testimonial-quote pulse'>
          <FormatQuoteOutlinedIcon className='white' />
        </Box>
      </Box>
      <Box className="testimonial-rating">
        <Rating name="read-only" value={rating} readOnly className='color1' precision={0.5} />
      </Box>

      <Box pb={1}>
        <Typography variant='h6' className='text-color fw4 al_jst'>
          {description}
        </Typography>
      </Box>

      <Divider />

      <Box pt={1}>
        <Typography variant='h5' className='title-color fw6'>
          {name}
        </Typography>
        <Typography variant='h6' className='text-color fs13 fw4 al_jst'>
          {designation}
        </Typography>
      </Box>
    </Box>
  </>
);





export const TestimonialSection = () => {


  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };


  return (
    <>

      <Box id="testimonials-section" className="section">
        <Container maxWidth="lg">
          <Grid container alignItems="center" justifyContent='center' spacing={4}>
            <Grid item lg={5} md={6} sm={12} xs={12}>
              <Box data-aos="fade-up" data-aos-duration="1500">
                <Box className="fx_c relative">
                  <Typography variant='h1' className='shadow-title'>Testimonials</Typography>
                  <TitleWithShape title={"TESTIMONIALS"} />
                </Box>
                <Box pt={2} className="fx_c al_center">
                  <Typography variant='h2' className='fw6 white'>
                    Let's Hear What Our Customers Are <span className='color1'>Saying</span>
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>


          <Box mt={4} mb={6} data-aos="zoom-in-up" data-aos-duration="1500">


            <CarouselMulti
              responsive={responsive}
              arrows={false}
              swipeable={true}
              draggable={true}
              showDots={true}
              infinite={true}
              autoPlay={true}
              pauseOnHover={true}
              keyBoardControl={true}
              customTransition="all 0.5s ease-in-out"
              autoPlaySpeed={5000}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-0-px"
            >
              {/* <>
                <Box className="testimonial-card no-select">
                  <Box className="testimonial-img">
                    <img src={BG} draggable={false} alt='Bill Gates' />
                    <Box className='testimonial-quote pulse'>
                      <FormatQuoteOutlinedIcon className='white' />
                    </Box>
                  </Box>
                  <Box className="testimonial-rating">
                    <Rating name="read-only" value={4} readOnly className='color1' />
                  </Box>

                  <Box>
                    <Typography variant='h6' className='text-color fw4 al_jst'>
                      Objectively visualize error-free tech for B2B alignment. Monotonectally harness an expanded array of models via effective collaboration. Globally synergize resource sucking value via cutting-edge.
                    </Typography>
                  </Box>

                  <Box pt={2}>
                    <Typography variant='h5' className='title-color fw6'>
                      Bill Gates
                    </Typography>
                    <Typography variant='h6' className='text-color fs13 fw4 al_jst'>
                      Former CEO of Microsoft
                    </Typography>
                  </Box>

                </Box>
              </>

              <>
                <Box className="testimonial-card no-select">
                  <Box className="testimonial-img">
                    <img src={LP} draggable={false} alt='Larry Page' />
                    <Box className='testimonial-quote pulse'>
                      <FormatQuoteOutlinedIcon className='white' />
                    </Box>
                  </Box>
                  <Box className="testimonial-rating">
                    <Rating name="read-only" value={4} readOnly className='color1' />
                  </Box>

                  <Box>
                    <Typography variant='h6' className='text-color fw4 al_jst'>
                      Objectively visualize error-free tech for B2B alignment. Monotonectally harness an expanded array of models via effective collaboration. Globally synergize resource sucking value via cutting-edge.
                    </Typography>
                  </Box>

                  <Box pt={2}>
                    <Typography variant='h5' className='title-color fw6'>
                      Larry Page
                    </Typography>
                    <Typography variant='h6' className='text-color fs13 fw4 al_jst'>
                      Former CEO of Google
                    </Typography>
                  </Box>

                </Box>
              </>


              <>
                <Box className="testimonial-card no-select">
                  <Box className="testimonial-img">
                    <img src={SP} draggable={false} alt='Sundar Pichai' />
                    <Box className='testimonial-quote pulse'>
                      <FormatQuoteOutlinedIcon className='white' />
                    </Box>
                  </Box>
                  <Box className="testimonial-rating">
                    <Rating name="read-only" value={4} readOnly className='color1' />
                  </Box>

                  <Box>
                    <Typography variant='h6' className='text-color fw4 al_jst'>
                      Objectively visualize error-free tech for B2B alignment. Monotonectally harness an expanded array of models via effective collaboration. Globally synergize resource sucking value via cutting-edge.
                    </Typography>
                  </Box>

                  <Box pt={2}>
                    <Typography variant='h5' className='title-color fw6'>
                      Sundar Pichai
                    </Typography>
                    <Typography variant='h6' className='text-color fs13 fw4 al_jst'>
                      CEO of Google
                    </Typography>
                  </Box>

                </Box>
              </>


              <>
                <Box className="testimonial-card no-select">
                  <Box className="testimonial-img">
                    <img src={MZ} draggable={false} alt='Mark Zukerberg' />
                    <Box className='testimonial-quote pulse'>
                      <FormatQuoteOutlinedIcon className='white' />
                    </Box>
                  </Box>
                  <Box className="testimonial-rating">
                    <Rating name="read-only" value={4} readOnly className='color1' />
                  </Box>

                  <Box>
                    <Typography variant='h6' className='text-color fw4 al_jst'>
                      Objectively visualize error-free tech for B2B alignment. Monotonectally harness an expanded array of models via effective collaboration. Globally synergize resource sucking value via cutting-edge.
                    </Typography>
                  </Box>

                  <Box pt={2}>
                    <Typography variant='h5' className='title-color fw6'>
                      Mark Zukerberg
                    </Typography>
                    <Typography variant='h6' className='text-color fs13 fw4 al_jst'>
                      CEO of Facebook
                    </Typography>
                  </Box>

                </Box>
              </> */}


              {testimonialsData.map((data, index) => (
                <TestimonialComponent key={index} {...data} />
              ))}


            </CarouselMulti>
          </Box>


        </Container>
      </Box>

    </>
  )
}
