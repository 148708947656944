import { Avatar, Box, Container, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import React from 'react' 
import WhyUs from "../../assets/images/why-us.png"
import { TitleWithShape } from '../../components/TitleWithShape' 
import Check from '../../assets/images/about-us/check-mark.png';


export const WhyChooseUsSection = () => {
  return (
    <>

      <Box id="why-choose-us" className="section">
        <Container maxWidth="lg">
          <Grid container alignItems="center" justifyContent='space-between' spacing={4}>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box data-aos="fade-up" data-aos-duration="800">
              <Box>
                <TitleWithShape title={"Why Choose Us"} />
              </Box>
              <Box pt={2}>
                <Typography variant='h2' className='fw6 title-color'>
                We Deal With The Aspects Professional <span className='color1'>IT Services</span>
                </Typography>
              </Box>

              <Box pt={2}>
                <Typography variant='h6' className='fw4 text-color' gutterBottom>
                Collaboratively envisioneer user friendly supply chains and cross unit imperative. Authoritativel fabricate competitive resource and holistic.
                </Typography>
              </Box>
            </Box>


              <Box pt={2} pb={4} data-aos="fade-up" data-aos-duration="800">
                <Grid container alignItems="center" justifyContent='space-between' spacing={4}>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <List>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <img src={Check} alt='check-icon' />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={<><Typography variant='h5' className='fw6 title-color'>Big Data Analysis</Typography></>} />
                      </ListItem>
                    </List>
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <List>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <img src={Check} alt='check-icon' />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={<><Typography variant='h5' className='fw6 title-color'>High Quality Security</Typography></>} />
                      </ListItem>
                    </List>
                  </Grid>


                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <List>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <img src={Check} alt='check-icon' />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={<><Typography variant='h5' className='fw6 title-color'>24/7 Online Support</Typography></>} />
                      </ListItem>
                    </List>
                  </Grid>


                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <List>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <img src={Check} alt='check-icon' />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={<><Typography variant='h5' className='fw6 title-color'>24/7 Check Team</Typography></>} />
                      </ListItem>
                    </List>
                  </Grid>

                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <List>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <img src={Check} alt='check-icon' />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={<><Typography variant='h5' className='fw6 title-color'>Business Improvement</Typography></>} />
                      </ListItem>
                    </List>
                  </Grid>

                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <List>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <img src={Check} alt='check-icon' />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={<><Typography variant='h5' className='fw6 title-color'>Easy Solutions</Typography></>} />
                      </ListItem>
                    </List>
                  </Grid>
                </Grid>
              </Box>

              {/* <Divider /> */}

              {/* <Box pt={4} pb={2}>
                <Stack direction={"row"} spacing={2}>
                  <Button variant='contained' className='cta-button-2' endIcon={<EastIcon />}>
                    Discover More
                  </Button>
                </Stack>
              </Box> */}

            </Grid>


            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Box className="relative no-select" data-aos="zoom-in" data-aos-duration="500">
                <Box className="abt-over" data-aos="zoom-in" data-aos-duration="1200">
                  <Box>
                    <Typography variant='h4'>
                      10K+
                    </Typography>
                    <Typography variant='h6'>
                      Active Clients
                    </Typography>
                  </Box>
                </Box>
                <img src={WhyUs} className='w100' draggable={false} alt='Why Choose Us - Side'/>
              </Box>
            </Grid>
           
          </Grid>
        </Container>
      </Box>

    </>
  )
}
