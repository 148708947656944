import { Avatar, Box, Button, Container, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, TextField, Typography } from '@mui/material'
import React from 'react'
import EastIcon from '@mui/icons-material/East';
import { TitleWithShape } from '../../components/TitleWithShape'
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';


import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';




const validationSchema = Yup.object({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  subject: Yup.string()
    .min(2, 'Too Short!')
    .max(100, 'Too Long!')
    .required('Required'),
  mobile: Yup.string()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(10, 'Must be exactly 10 digits')
    .max(10, 'Must be exactly 10 digits')
    .required('Required'),
  message: Yup.string()
    .min(10, 'Too Short!')
    .max(500, 'Too Long!')
    .required('Required'),
});

const initialValues = {
  name: '',
  email: '',
  subject: '',
  mobile: '',
  message: '',
};




export const ContactUsSection = () => {
  return (
    <>

      <Box id="contact-us" className="section">
        <Container maxWidth="lg">
          <Grid container alignItems="flex-start" justifyContent='space-between' spacing={8}>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Box data-aos="fade-up" data-aos-duration="1500">
                <Box>
                  <TitleWithShape title={"APPOINTMENT NOW"} />
                </Box>
                <Box pt={2}>
                  <Typography variant='h2' className='fw6 title-color'>
                    We're Ready to Discuss Your  <span className='color1'>Opportunities</span>
                  </Typography>
                </Box>
              </Box>


              <Box pt={2} pb={2} data-aos="fade-up" data-aos-duration="1500">
                <Typography variant='h6' className='fw4 text-color' gutterBottom>
                  Progressively morph principle-centered e-markets without an expanded array of opportunities. Conveniently incubate e-tailers for extensive leadership skills. Holisticly extend leading-edge vortals vis-a-vis 24/7 e-markets. Appropriately evolve efficient functionalities with installed base relationships.
                </Typography>
              </Box>

              <Divider />

              <Box pt={2} pb={4} data-aos="fade-up" data-aos-duration="1500">
                <Grid container alignItems="center" justifyContent='space-between' spacing={2}>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Box className="conact-card-list">
                      <List>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>
                              <CallIcon fontSize='large' />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={<><Typography variant='h6' className='fw4 text-color'>Quick Call Us:</Typography></>}
                            secondary={<><Typography variant='h5' className='fw6 title-color'>+91 83810 07317</Typography></>} />
                        </ListItem>
                      </List>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Box className="conact-card-list">
                      <List>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>
                              <MailIcon fontSize='large' />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={<><Typography variant='h6' className='fw4 text-color'>Quick Mail Us:</Typography></>}
                            secondary={<><Typography variant='h5' className='fw6 title-color'>info@company.com</Typography></>} />
                        </ListItem>
                      </List>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

            </Grid>


            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Box pt={2} className="al_center" data-aos="fade-up" data-aos-duration="1500">
                <Typography variant='h2' className='fw6 title-color'>
                  Make an Appointment
                </Typography>
              </Box>


              {/* <Box mt={4} data-aos="fade-up" data-aos-duration="1500">
                <Grid container alignItems="center" justifyContent='space-between' spacing={2}>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Box>
                      <TextField id="outlined-basic" label="Name" name='name' variant="outlined" size='small' fullWidth />
                    </Box>
                  </Grid>

                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Box>
                      <TextField id="outlined-basic" label="Email" name="email" variant="outlined" size='small' fullWidth />
                    </Box>
                  </Grid>

                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Box>
                      <TextField id="outlined-basic" label="Subject" name='subject' variant="outlined" size='small' fullWidth />
                    </Box>
                  </Grid>

                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Box>
                      <TextField id="outlined-basic" label="Mobile Number" name='mobile' variant="outlined" size='small' fullWidth />
                    </Box>
                  </Grid>


                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box>
                      <TextField id="outlined-basic" label="Message" name="message" variant="outlined" size='small' fullWidth multiline rows={4} />
                    </Box>
                  </Grid>

                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box mt={1}>
                      <Button variant='contained' className='cta-button-2' type='submit' fullWidth endIcon={<EastIcon />}>
                        Make An Appointment
                      </Button>
                    </Box>
                  </Grid>

 

                </Grid>
              </Box> */}



<Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        // Handle form submission
        console.log(values);
      }}
    >
      {({ errors, touched }) => (
        <Form>
          <Box mt={4} data-aos="fade-up" data-aos-duration="1500">
            <Grid container alignItems="center" justifyContent='space-between' spacing={3}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Box>
                  <Field
                    as={TextField}
                    id="name"
                    name="name"
                    label="Name"
                    variant="outlined"
                    size='small'
                    fullWidth
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </Box>
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Box>
                  <Field
                    as={TextField}
                    id="email"
                    name="email"
                    label="Email"
                    variant="outlined"
                    size='small'
                    fullWidth
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Box>
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Box>
                  <Field
                    as={TextField}
                    id="subject"
                    name="subject"
                    label="Subject"
                    variant="outlined"
                    size='small'
                    fullWidth
                    error={touched.subject && Boolean(errors.subject)}
                    helperText={touched.subject && errors.subject}
                  />
                </Box>
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Box>
                  <Field
                    as={TextField}
                    id="mobile"
                    name="mobile"
                    label="Mobile Number"
                    variant="outlined"
                    size='small'
                    fullWidth
                    error={touched.mobile && Boolean(errors.mobile)}
                    helperText={touched.mobile && errors.mobile}
                  />
                </Box>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box>
                  <Field
                    as={TextField}
                    id="message"
                    name="message"
                    label="Message"
                    variant="outlined"
                    size='small'
                    fullWidth
                    multiline
                    rows={4}
                    error={touched.message && Boolean(errors.message)}
                    helperText={touched.message && errors.message}
                  />
                </Box>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box mt={1}>
                  <Button
                    variant='contained'
                    className='cta-button-2'
                    type='submit'
                    fullWidth
                    endIcon={<EastIcon />}
                  >
                    Make An Appointment
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Form>
      )}
    </Formik>



            </Grid>


          </Grid>
        </Container>
      </Box>

    </>
  )
}
